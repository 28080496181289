.logo {
  position: absolute;
  left: 40px;
  top: 20px;
  width: 100px;
}

.navBar-links {
  list-style-type: none;
  margin-top: 25px;
  position: relative;
  float: right;
  font-family: Nominee Light;
}

.navBar-links li {
  display: inline-block;
  margin: 15px;
}

.navBar-link {
  color: black;
}

.navBar-link:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.navBar-button {
  background-color: transparent;
  font-family: Nominee;
  color: var(--primary-color);
  border-radius: 10px;
  border: 2px solid var(--primary-color);
}

.navBar-button:hover {
  color: white;
  background-color: var(--primary-color);
}
