@media only screen and (max-width: 600px) {
  .aboutUs-space {
    margin-top: 130px;
  }

  .aboutUs-space p {
    font-size: 12px;
  }

  .aboutUs-page-title {
    font-family: Nominee Bold;
  }

  .aboutUs-page-text {
    font-family: Nominee;
    font-size: 12px;
  }

  .aboutUs-page-image {
    width: 90%;
  }
}
