@font-face {
    font-family: Nominee;
    src: url("./Assets/Fonts/Nominee Regular.otf") format("opentype");
  }
  
 @font-face {
    font-family: Nominee Medium;
    src: url("./Assets/Fonts/Nominee Medium.otf") format("opentype");
  }

  @font-face {
    font-family: Nominee Light;
    src: url("./Assets/Fonts/Nominee Light.otf") format("opentype");
  }

  @font-face {
    font-family: Nominee Bold;
    src: url("./Assets/Fonts/Nominee Bold.otf") format("opentype");
  }

  @font-face {
    font-family: Nominee Extra Bold;
    src: url("./Assets/Fonts/Nominee Extra Bold.otf") format("opentype");
  }

  :root {
    --primary-color: #a88244;
    --secondary-color: #018c9a;
    --background-color: #eaecec;
  }

  html,body {
    background-color: var(--background-color);
  }