@media only screen and (min-width: 600px) {
  body {
    background-color: transparent;
  }
  
  .hero-row-space {
    margin-top: 190px;
  }
  
  .hero {
    width : 160%;
    margin-top: -80px;
    margin-left: -150px;
  }

  .heroMobile {
    display: none;
  }
  
  .hero-title {
    font-family: Nominee Extra Bold;
    text-transform: uppercase;
    font-size: 35px;
  }
  
  .hero-subtitle {
    font-family: Nominee Medium;
    color: var(--secondary-color);
    font-size: 25px;
    margin-top: 5%;
    text-transform: uppercase;
  }
  
  .hero-button {
    font-family: Nominee Extra Bold;
    font-size: 25px;
    background: rgb(187,158,12);
    background: linear-gradient(90deg, rgba(187,158,12,1) 0%, rgba(232,209,95,1) 25%, rgba(187,158,12,1) 50%, rgba(232,209,95,1) 75%, rgba(187,158,12,1) 100%);
    padding: 1% 3%;
    border-radius: 15px;
    border: none;
    margin-top: 5%;
  }
  
  .hero-button:hover {
    color: white;
  }
  
  /* General css below hero page */
  
  .row-space {
    margin-top: 170px;
  }
  
  .row-space-2 {
    margin-top: 85px;
  }
  
  .center {
    text-align: center;
  }
  
  .line {
    width: 100%;
    border-width: 1.5px;
    margin-top: 85px;
  }
  
  /* Welcome section */
  
  .yes-title {
    font-family: Nominee Extra Bold;
  font-size: 35px
  }
  
  .yes-title span {
    color: var(--primary-color);
    font-size: 50px;
  }
  
  .circular-border {
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    border: 4px solid var(--primary-color);
    border-radius: 100%;
    width: 300px;
    height: 300px;
  }
  
  .circular-border p {
    font-family: Nominee Extra Bold;
    font-size: 25px;
    text-transform: uppercase;
  }
  
  .circular-border span {
    color: var(--primary-color);
    font-size: 30px;
  }
  
  .circular-border img {
    width: 100px
  }
  
  .circular-border #candles {
    width: 80px;
    margin-top: 20px;
  }
  
  /* Affordable world of trading */
  
  .tradingWorld-title {
    font-size: 30px;
    font-family: Nominee Extra Bold;
    color: var(--primary-color);
  }
  
  .tradingWorld-text {
    font-family: Nominee;
    font-size: 20px;
    margin-top: 5%;
  }
  
  .tradingWorld-subTitle {
    font-family: Nominee Extra Bold;
    font-size: 20px;
    margin-top: 5%;
    color: var(--primary-color);
  }
  
  .tradingWorld-button {
    background-color: transparent;
    font-family: Nominee Extra Bold;
    border-radius: 10px;
    border: 2px solid var(--primary-color);
    width: 320px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 20px;
  }
  
  .tradingWorld-button:hover {
    background-color: var(--primary-color);
    color: white
  }
  
  .button-icon-left {
    width: 30px;
    transform: rotate(-90deg);
    margin-right: 10px;
  }
  
  .button-icon-right {
    width: 30px;
    transform: rotate(90deg);
    margin-left: 10px;
  }
  
  /* About Us Section */
  
  .aboutUs-title {
    font-family: Nominee Extra Bold;
    font-size: 35px;
    text-transform: uppercase;
  }
  
  .aboutUs-subtitle {
    font-family: Nominee Extra Bold;
    font-size: 30px;
    text-transform: uppercase;
    color: var(--secondary-color);
  }
  
  .aboutUs-text {
    margin-top: 5%;
    font-family: Nominee;
    font-size: 20px;
    margin-left: 40px;
  }
  
  .aboutUs-image {
    width: 80%;
  }
  
  .button-space {
    margin-top: 5%;
  }
  
  .demo-account-button { 
    font-family: Nominee Extra Bold;
    background-color: white;
    border-radius: 10px;
    border: none;
    padding: 10px 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    float: right;
  }
  
  .demo-account-button:hover {
    background-color: var(--secondary-color);
    color: white;
  }
  
  .real-account-button {
    font-family: Nominee Extra Bold;
    background-color: var(--secondary-color);
    border-radius: 10px;
    border: none;
    padding: 10px 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: white;
  }
  
  .real-account-button:hover {
    background-color: white;
    color: black;
  }
  
  /* Download Section */
  
  .download-title {
    font-size: 35px;
    font-family: Nominee Bold;
  }
  
  .download-subtitle {
    font-size: 45px;
    font-family: Nominee Extra Bold;
    color: var(--primary-color)
  }
  
  .download-text {
    font-family: Nominee Bold;
    font-size: 25px;
  }
  
  .download-icon {
    width: 30px;
  }
  
  .download-button {
    margin-top: 2%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: none;
  padding: 15px 25px;
  font-family: Nominee Extra Bold;
  font-size: 20px;
  border-radius: 15px;
  }
  
  .download-button:hover {
    background-color: var(--primary-color);
    color: white;
  }
  
  .download-image {
    width: 50px;
  }
   
}