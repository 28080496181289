@media only screen and (max-width: 600px) {
  .logo {
    position: absolute;
    left: 10px;
    top: 15px;
    width: 100px;
  }

  .navBar-links {
    list-style-type: none;
    margin-top: 5px;
    font-family: Nominee Light;
  }

  .navBar-links li {
    margin-right: 10px;
    margin-bottom: -10px;
    float: right;
  }

  .navBar-link {
    color: black;
    font-size: 14px;
  }

  .navBar-link:hover {
    color: var(--primary-color);
    text-decoration: none;
  }

  .navBar-button {
    position: absolute;
    margin-top: 30px;
    right: 10px;
    font-size: 12px;
    background-color: transparent;
    font-family: Nominee;
    color: var(--primary-color);
    border-radius: 10px;
    border: 2px solid var(--primary-color);
  }

  .navBar-button:hover {
    color: white;
    background-color: var(--primary-color);
  }
}
