@media only screen and (max-width: 600px) {
    body {
        background-color: transparent;
      }
      
      .hero-row-space {
        margin-top: 120px;
      }
      
      .hero {
        display: none;
      }

      .heroMobile {
        width: 100%;
        margin-top: 30px;
      }
      
      .hero-title {
        font-family: Nominee Extra Bold;
        text-transform: uppercase;
        font-size: 20px;
      }
      
      .hero-subtitle {
        font-family: Nominee Medium;
        color: var(--secondary-color);
        font-size: 15px;
        margin-top: 5%;
        text-transform: uppercase;
      }
      
      .hero-button {
        font-family: Nominee Extra Bold;
        font-size: 15px;
        background: rgb(187,158,12);
        background: linear-gradient(90deg, rgba(187,158,12,1) 0%, rgba(232,209,95,1) 25%, rgba(187,158,12,1) 50%, rgba(232,209,95,1) 75%, rgba(187,158,12,1) 100%);
        padding: 1% 3%;
        border-radius: 15px;
        border: none;
        margin-top: 5%;
      }
      
      .hero-button:hover {
        color: white;
      }
      
      /* General css below hero page */
      
      .row-space {
        margin-top: 70px;
      }
      
      .row-space-2 {
        margin-top: 45px;
      }
      
      .center {
        text-align: center;
      }
      
      .line {
        width: 80%;
        border-width: 1.5px;
        margin-top: 60px;
      }
      
      /* Welcome section */
      
      .yes-title {
        font-family: Nominee Extra Bold;
      font-size: 20px
      }
      
      .yes-title span {
        color: var(--primary-color);
        font-size: 30px;
      }
      
      .circular-border {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        padding-top: 20px;
        border: 4px solid var(--primary-color);
        border-radius: 100%;
        width: 200px;
        height: 200px;
      }
      
      .circular-border p {
        font-family: Nominee Extra Bold;
        font-size: 15px;
        text-transform: uppercase;
      }
      
      .circular-border span {
        color: var(--primary-color);
        font-size: 20px;
      }
      
      .circular-border img {
        width: 60px
      }
      
      .circular-border #candles {
        width: 40px;
        margin-top: 20px;
      }
      
      /* Affordable world of trading */
      
      .tradingWorld-title {
        font-size: 20px;
        font-family: Nominee Extra Bold;
        color: var(--primary-color);
      }
      
      .tradingWorld-text {
        font-family: Nominee;
        font-size: 12px;
        margin-top: 5%;
      }
      
      .tradingWorld-subTitle {
        font-family: Nominee Extra Bold;
        font-size: 15px;
        margin-top: 5%;
        color: var(--primary-color);
      }
      
      .tradingWorld-button {
        background-color: transparent;
        font-family: Nominee Extra Bold;
        border-radius: 10px;
        border: 2px solid var(--primary-color);
        width: 220px;
        padding-top: 7px;
        padding-bottom: 7px;
        font-size: 14px;
      }
      
      .tradingWorld-button:hover {
        background-color: var(--primary-color);
        color: white
      }
      
      .button-icon-left {
        width: 25px;
        transform: rotate(-90deg);
        margin-right: 10px;
      }
      
      .button-icon-right {
        width: 25px;
        transform: rotate(90deg);
        margin-left: 10px;
      }
      
      
      
      /* About Us Section */
      
      .aboutUs-title {
        font-family: Nominee Extra Bold;
        font-size: 20px;
        text-transform: uppercase;
      }
      
      .aboutUs-subtitle {
        font-family: Nominee Extra Bold;
        font-size: 15px;
        text-transform: uppercase;
        color: var(--secondary-color);
      }
      
      .aboutUs-text {
        margin-top: 5%;
        font-family: Nominee;
        font-size: 12px;
        margin-left: 15px;
      }
      
      .aboutUs-image {
        width: 70%;
      }
      
      .button-space {
        margin-top: 5%;
      }
      
      .demo-account-button { 
        font-family: Nominee Extra Bold;
        font-size: 14px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        border-radius: 10px;
        border: none;
        padding: 10px 15px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-bottom: 20px;
      }
      
      .demo-account-button:hover {
        background-color: var(--secondary-color);
        color: white;
      }
      
      .real-account-button {
        font-family: Nominee Extra Bold;
        font-size: 14px;
        width: 100%;
        background-color: var(--secondary-color);
        border-radius: 10px;
        border: none;
        padding: 10px 15px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        color: white;
        text-align: center;
      }
      
      .real-account-button:hover {
        background-color: white;
        color: black;
      }
      
      /* Download Section */
      
      .download-title {
        font-size: 20px;
        font-family: Nominee Bold;
      }
      
      .download-subtitle {
        font-size: 25px;
        font-family: Nominee Extra Bold;
        color: var(--primary-color)
      }
      
      .download-text {
        font-family: Nominee Bold;
        font-size: 15px;
      }
      
      .download-icon {
        width: 20px;
      }
      
      .download-button {
        margin-top: 5%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border: none;
        padding: 10px 15px;
        font-family: Nominee Extra Bold;
        font-size: 14px;
        border-radius: 15px;
      }
      
      .download-button:hover {
        background-color: var(--primary-color);
        color: white;
      }
      
      .download-image {
        width: 50px;
      }
}