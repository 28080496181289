.aboutUs-space {
  margin-top: 150px;
}

.aboutUs-space p {
  font-size: 20px;
}

.aboutUs-page-title {
  font-family: Nominee Bold;
}

.aboutUs-page-text {
  font-family: Nominee;
  font-size: 20px;
}

.aboutUs-page-image {
  width: 90%;
}
