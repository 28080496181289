.emailForm-space {
    margin-top: 250px;
}

.emailForm {
    background-color: transparent;
    font-family: Nominee;
}

.emailForm-first-name, .emailForm-last-name, .emailForm-email, .emailForm-phone-number, .emailForm-account-type {
    width: 100%;
    background-color: transparent;
    border-radius: 10px;
    border: 2px solid var(--primary-color);
    margin-bottom: 15px;
    padding: 5px 10px;
}

.emailForm-checkbox {
    position: inline-block;
}

.emailForm-terms-label {
    position: relative;
}

.emailForm-button {
    margin-top: 15px;
    background-color: transparent;
    border-radius: 10px;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.emailForm-button:hover {
    background-color: var(--primary-color);
    color: white;
}