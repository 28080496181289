@media only screen and (max-width: 600px) {
    .emailForm-space {
        margin-top: 150px;
    }
    
    .emailForm {
        background-color: transparent;
        font-family: Nominee;
        font-size: 12px;
    }
    
    .emailForm-first-name, .emailForm-last-name, .emailForm-email, .emailForm-phone-number, .emailForm-account-type {
        width: 80%;
        background-color: transparent;
        border-radius: 10px;
        border: 2px solid var(--primary-color);
        margin-bottom: 15px;
        padding: 5px 10px;
    }
    
    .emailForm-checkbox {
        position: inline-block;
    }
    
    .emailForm-terms-label {
        position: relative;
        font-size: 12px;
    }
    
    .emailForm-button {
        margin-top: 15px;
        background-color: transparent;
        border-radius: 10px;
        border: 2px solid var(--primary-color);
        color: var(--primary-color);
    }
    
    .emailForm-button:hover {
        background-color: var(--primary-color);
        color: white;
    }
}