@media only screen and (max-width: 600px) {
  .section-split {
    border-width: 5px;
    margin-top: 75px;
    border-color: var(--primary-color);
  }

  .contactUs-line {
    border-width: 2px;
  }

  .contactUs-left {
    margin-top: 10px;
    font-family: Nominee;
    margin-left: 0px;
  }

  .contactUs-left p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .contactUs-left a {
    font-size: 10px;
    color: black;
  }

  .contactUs-left a:hover {
    color: black;
  }

  .contactUs-left img {
    width: 100px;
  }

  .contactUs-left-button-container {
    display: flex;
  }

  .footer-facebook {
    width: 20px;
    margin-left: 10px;
  }

  .footer-facebook img {
    width: 20px;
  }

  .contactUs-left-button button {
    background-color: transparent;
    font-family: Nominee;
    color: var(--primary-color);
    border-radius: 10px;
    border: 2px solid var(--primary-color);
  }

  .contactUs-left-button button:hover {
    color: white;
    background-color: var(--primary-color);
  }

  .contactUs-middle {
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    color: var(--primary-color);
  }

  .contactUs-middle-links {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    width: fit-content;
  }

  .contactUs-middle-links span {
    font-weight: bold;
    font-size: 18px;
    color: var(--primary-color);
  }

  .contactUs-contact-us {
    margin-top: 40px;
  }

  .contactUs-right {
    display: flex;
    flex-direction: row;
  }

  .contactUs-right-location-one {
    width: 40px;
    margin-right: 10px;
  }

  .contactUs-right-location-two {
    width: 28px;
    margin-right: 10px;
  }

  .contactUs-right-location {
    width: 20px;
    margin-right: 10px;
  }

  .contactUs-right-email {
    width: 20px;
    height: 18px;
    margin-right: 10px;
    margin-top: 4px;
  }

  .contactUs-disclosure {
    font-family: Nominee;
    font-size: 10px;
    margin-top: 20px;
  }

  .contactUs-disclosure span {
    font-family: Nominee Bold;
  }

  .copyright {
    font-family: Nominee Extra Bold;
    font-size: 10px;
  }

  .copyright span {
    font-family: Nominee Medium;
  }

  .credits {
    font-family: Nominee Light;
    font-size: 10px;
    margin-top: -15px;
  }

  .credits a {
    font-family: Nominee Bold;
    color: black;
  }

  .credits a:hover {
    color: black;
  }
}
