.section-split {
  border-width: 5px;
  margin-top: 75px;
  border-color: var(--primary-color);
}

.contactUs-line {
  border-width: 2px;
}

.contactUs-left {
  margin-left: 30px;
  margin-top: 30px;
  font-family: Nominee;
}

.contactUs-left p {
  font-size: 16px;
  margin-bottom: 20px;
}

.contactUs-left a {
  color: black;
}

.contactUs-left a:hover {
  color: black;
}

.contactUs-left img {
  width: 100px;
}

.contactUs-left-button-container {
  display: flex;
}

.footer-facebook {
  width: 25px;
  margin-left: 10px;
}

.footer-facebook img {
  width: 25px;
}

.contactUs-left-button button {
  background-color: transparent;
  font-family: Nominee;
  color: var(--primary-color);
  border-radius: 10px;
  border: 2px solid var(--primary-color);
}

.contactUs-left-button button:hover {
  color: white;
  background-color: var(--primary-color);
}

.contactUs-middle {
  margin-top: 30px;
  font-weight: bold;
  font-size: 20px;
  color: var(--primary-color);
}

.contactUs-middle-links {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  width: fit-content;
}

.contactUs-middle-links span {
  font-weight: bold;
  font-size: 18px;
  color: var(--primary-color);
}

.contactUs-right {
  display: flex;
  flex-direction: row;
}

.contactUs-right-location-one {
  width: 65px;
  margin-right: 10px;
}

.contactUs-right-location-two {
  width: 46px;
  margin-right: 10px;
}

.contactUs-right-location {
  width: 25px;
  margin-right: 10px;
}

.contactUs-right-email {
  width: 25px;
  height: 22px;
  margin-right: 10px;
}

.contactUs-disclosure {
  font-family: Nominee;
  font-size: 14px;
  margin-top: 20px;
}

.contactUs-disclosure span {
  font-family: Nominee Bold;
}

.copyright {
  font-family: Nominee Extra Bold;
}

.copyright span {
  font-family: Nominee Medium;
}

.credits {
  font-family: Nominee Light;
  font-size: 12px;
  margin-top: -15px;
}

.credits a {
  font-family: Nominee Bold;
  color: black;
}

.credits a:hover {
  color: black;
}
